<template>
  <!-- Form with Password -->
  <form @submit.prevent="switchRoute">
    <div class="mb-3 row">
      <div class="col-md-6">
        <div>
          <!-- <label for="first_name" class="form-label">First Name</label> -->
          <input
            type="text"
            class="form-control"
            placeholder="First Name"
            v-model.trim="firstName"
            :readonly="isRegistering"
            ref="focusInput"
          />
        </div>
        <div
          class="input-errors"
          v-for="error of v$.firstName.$errors"
          :key="error.$uid"
        >
          <div class="error-msg">{{ error.$message }}</div>
        </div>
      </div>
      <div class="col-md-6">
        <div>
          <!-- <label for="last_name" class="form-label">Last Name</label> -->
          <input
            type="text"
            class="form-control"
            placeholder="Last Name"
            v-model.trim="lastName"
            :readonly="isRegistering"
          />
        </div>
        <div
          class="input-errors"
          v-for="error of v$.lastName.$errors"
          :key="error.$uid"
        >
          <div class="error-msg">{{ error.$message }}</div>
        </div>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-md-12">
        <div>
          <!-- <label for="email" class="form-label">Email</label> -->
          <input
            type="email"
            class="form-control"
            placeholder="Email"
            v-model.trim="email"
            :readonly="isRegistering"
            aria-describedby="emailHelp"
          />
        </div>
        <div
          class="input-errors"
          v-for="error of v$.email.$errors"
          :key="error.$uid"
        >
          <div class="error-msg">{{ error.$message }}</div>
        </div>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-md-6">
        <div>
          <!-- <label for="country_code" class="form-label">Country Code</label> -->
          <Multiselect
            v-model="countryCodeOptions.value"
            v-bind="countryCodeOptions"
          />
        </div>
        <div
          class="input-errors"
          v-for="error of v$.countryCodeOptions.value.$errors"
          :key="error.$uid"
        >
          <div class="error-msg">{{ error.$message }}</div>
        </div>
      </div>
      <div class="col-md-6">
        <div>
          <!-- <label for="phone" class="form-label">Phone Number</label> -->
          <input
            type="text"
            class="form-control"
            placeholder="Phone no. without country code"
            v-model.number="phone"
            :readonly="isRegistering"
            aria-describedby="phoneHelp"
          />
        </div>
        <div
          class="input-errors"
          v-for="error of v$.phone.$errors"
          :key="error.$uid"
        >
          <div class="error-msg">{{ error.$message }}</div>
        </div>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-md-6">
        <div>
          <!-- <label for="standard" class="form-label">Standard</label> -->
          <Multiselect
            v-model="standardOptions.value"
            v-bind="standardOptions"
          />
        </div>
        <div
          class="input-errors"
          v-for="error of v$.standardOptions.value.$errors"
          :key="error.$uid"
        >
          <div class="error-msg">{{ error.$message }}</div>
        </div>
      </div>
      <div class="col-md-6">
        <div>
          <!-- <label for="school" class="form-label">School</label> -->
          <Multiselect
            v-model="schoolOptions.value"
            v-bind="schoolOptions"
            @select="checkIfOtherSchool"
          />
        </div>
        <div
          class="input-errors"
          v-for="error of v$.schoolOptions.value.$errors"
          :key="error.$uid"
        >
          <div class="error-msg">{{ error.$message }}</div>
        </div>
      </div>
    </div>
    <div v-if="isOtherSchool" class="row mb-3">
      <div class="col-md-12">
        <div>
          <!-- <label for="first_name" class="form-label">First Name</label> -->
          <input
            type="text"
            class="form-control"
            placeholder="School Name"
            v-model.trim="schoolName"
            :readonly="isRegistering"
          />
        </div>
        <div
          class="input-errors"
          v-for="error of v$.schoolName.$errors"
          :key="error.$uid"
        >
          <div class="error-msg">{{ error.$message }}</div>
        </div>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-md-6 pass-input">
        <span class="input-actions">
          <a class="show-password">
            <i
              @click="showPassword = !showPassword"
              class="fa"
              :class="[showPassword ? 'fa-eye' : 'fa-eye-slash']"
              aria-hidden="true"
            ></i>
          </a>
        </span>
        <input
          :type="[showPassword ? 'text' : 'password']"
          class="form-control"
          placeholder="Password"
          v-model.trim="password"
          :readonly="isRegistering"
        />
        <div
          class="input-errors"
          v-for="error of v$.password.$errors"
          :key="error.$uid"
        >
          <div class="error-msg">{{ error.$message }}</div>
        </div>
      </div>
      <div class="col-md-6 pass-input">
        <span class="input-actions">
          <a class="show-password">
            <i
              @click="showConfirmationPassword = !showConfirmationPassword"
              class="fa"
              :class="[showConfirmationPassword ? 'fa-eye' : 'fa-eye-slash']"
              aria-hidden="true"
            ></i>
          </a>
        </span>
        <input
          :type="[showConfirmationPassword ? 'text' : 'password']"
          class="form-control"
          placeholder="Password Confirmation"
          v-model.trim="passwordConfirmation"
          :readonly="isRegistering"
        />
        <div
          class="input-errors"
          v-for="error of v$.passwordConfirmation.$errors"
          :key="error.$uid"
        >
          <div class="error-msg">{{ error.$message }}</div>
        </div>
      </div>
    </div>
    <div v-if="isEmailValid" class="mb-3 pass-input">
      <span class="input-actions">
        <a
          href="javascript:void(0);"
          @click="requestVerificationOTP"
          v-if="isOTPSent"
          >Resend OTP</a
        >
      </span>
      <!-- <label for="otp" class="form-label">otp</label> -->
      <input
        type="text"
        id="otp_field"
        class="form-control"
        placeholder="- - - - -"
        v-model.trim="otp"
        ref="focusOTPField"
      />
      <div
        class="input-errors"
        v-for="error of v$.otp.$errors"
        :key="error.$uid"
      >
        <div class="error-msg">{{ error.$message }}</div>
      </div>
    </div>
    <div class="user-agreement">
      <input
        type="checkbox"
        v-model.trim="acceptTerms"
        :readonly="isRegistering"
        @click="isCheckbox()"
        :checked="isChecked"
        :disabled="isDisabled"
      />
      <div
        class="input-errors"
        v-for="error of v$.acceptTerms.$errors"
        :key="error.$uid"
      >
        <div class="error-msg">{{ error.$message }}</div>
      </div>
      By continuing, you agree to our
      <router-link target="_blank" :to="{ name: 'PrivacyPolicy' }"
        >Privacy Policy</router-link
      >
      and
      <router-link target="_blank" :to="{ name: 'TermsAndConditions' }"
        >Terms And Conditions</router-link
      >.
    </div>
    <div class="text-right cta-section">
      <button
        type="submit"
        class="btn cta-primary"
        :disabled="isRegistering"
        v-if="!isVerificationPending"
      >
        <span v-if="!isRegistering"> Register </span>
        <span v-else> Registering... </span>
      </button>
      <button
        type="submit"
        class="btn cta-primary"
        :disabled="isVerifying"
        v-if="isVerificationPending"
      >
        <span v-if="!isVerifying"> Verify </span>
        <span v-else> Verifying... </span>
      </button>
    </div>
  </form>
</template>

<script>
import AuthService from "@/services/AuthService";
import CommonService from "@/services/CommonService";
import useVuelidate from "@vuelidate/core";
import Multiselect from "@vueform/multiselect";
import {
  required,
  requiredIf,
  email,
  maxLength,
  minLength,
  sameAs,
  helpers,
} from "@vuelidate/validators";

export default {
  name: "RegisterPasswordForm",
  components: {
    Multiselect,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      phone: "",
      countryCode: "",
      email: "",
      password: "",
      passwordConfirmation: "",
      otp: "",
      schoolName: "",
      isRegistering: false,
      isOtherSchool: false,
      isEmailValid: false,
      isOTPSent: false,
      isVerificationPending: false,
      isVerifying: false,
      showPassword: false,
      showConfirmationPassword: false,
      // Select options
      countryCodeOptions: {
        value: null,
        options: [],
        placeholder: "Select Country Code",
        canDeselect: false,
        loading: true,
        // required: true,
        searchable: true,
        disabled: false,
      },
      schoolOptions: {
        value: null,
        options: [],
        placeholder: "Select School",
        canDeselect: false,
        // required: true,
        searchable: true,
        disabled: false,
      },
      standardOptions: {
        value: null,
        options: [],
        placeholder: "Select Standard",
        canDeselect: false,
        // required: true,
        searchable: true,
        disabled: false,
      },
      acceptTerms: "",
      isChecked: false,
      isDisabled: false,
    };
  },
  validations() {
    return {
      firstName: {
        required: helpers.withMessage("Please enter first name", required),
        maxLength: helpers.withMessage(
          "Maximum 20 characters are allowed for first name",
          maxLength(20)
        ),
      },
      lastName: {
        required: helpers.withMessage("Please enter last name", required),
        maxLength: helpers.withMessage(
          "Maximum 20 characters are allowed for last name",
          maxLength(20)
        ),
      },
      email: {
        required: helpers.withMessage("Please enter email", required),
        email: helpers.withMessage("Please enter valid email", email),
      },
      countryCodeOptions: {
        value: {
          required: helpers.withMessage("Please select country code", required),
        },
      },
      phone: {
        required: helpers.withMessage("Please enter phone no.", required),
        maxLength: helpers.withMessage(
          "Max of 15 digits allowed",
          maxLength(15)
        ),
        minLength: helpers.withMessage(
          "Please enter valid phone no",
          minLength(6)
        ),
      },
      schoolOptions: {
        value: {
          required: helpers.withMessage("Please select school", required),
        },
      },
      standardOptions: {
        value: {
          required: helpers.withMessage("Please select standard", required),
        },
      },
      password: {
        required: helpers.withMessage("Please enter password", required),
        minLength: helpers.withMessage(
          "Password should be atleast of 5 characters",
          minLength(5)
        ),
      },
      passwordConfirmation: {
        required: helpers.withMessage(
          "Please enter confirm password",
          required
        ),
        minLength: helpers.withMessage(
          "Password should be atleast of 5 characters",
          minLength(5)
        ),
        sameAsPassword: helpers.withMessage(
          "Password mismatch",
          sameAs(this.password)
        ),
      },
      // FIXME: requiredIf validation is not working as expected
      schoolName: {
        requiredIfOtherSchool: helpers.withMessage(
          "Please enter school name",
          requiredIf(this.isOtherSchool)
        ),
      },
      otp: {
        requiredIfEmailVerified: helpers.withMessage(
          "Please enter otp",
          requiredIf(this.isEmailValid)
        ),
      },
      acceptTerms: {
        required: helpers.withMessage(
          "Please accept privacy policy and terms & conditions",
          required
        ),
      },
    };
  },
  mounted() {
    this.activeSchools();
    this.activeStandards();
    this.allCountries();
    this.$refs.focusInput.focus();
  },

  methods: {
    isCheckbox() {
      if (this.acceptTerms == false) {
        this.isChecked = true;
        this.isDisabled = true;
      }
    },
    // Note:- this is to control the enter key button press by user without clicking on any submit button
    switchRoute() {
      if (!this.isVerificationPending) {
        this.register();
      } else {
        this.isVerifying = !this.isVerifying;
        this.verifyRegistration();
      }
    },
    checkIfOtherSchool() {
      this.schoolName = "";
      if (this.schoolOptions.value === 3) {
        // user id of the Other school = 3
        this.isOtherSchool = true;
      } else {
        this.isOtherSchool = false;
      }
    },
    async activeSchools() {
      await CommonService.getActiveSchools()
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.schoolOptions.options = []; // to clear previously loaded options
            let schools = response.data.schools;
            if (schools.length > 0) {
              schools.map((school) => {
                let options = {
                  value: school.id,
                  label: school.school_name,
                };
                this.schoolOptions.options.push(options);
              });
            }
            // console.log(this.schoolOptions);
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async activeStandards() {
      await CommonService.getActiveStandards()
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.standardOptions.options = []; // to clear previously loaded options
            let standards = response.data.standards;
            if (standards.length > 0) {
              standards.map((standard) => {
                let options = {
                  value: standard.id,
                  label: standard.standard_title,
                };
                this.standardOptions.options.push(options);
              });
            }
            // console.log(this.standardOptions);
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async allCountries() {
      await CommonService.getAllCountries()
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.countryCodeOptions.options = []; // to clear previously loaded options
            this.countryCodeOptions.loading = false;
            let countries = response.data.countries;
            if (countries.length > 0) {
              countries.map((country) => {
                let options = {
                  value: country.phone_code,
                  label: country.country_name + " (" + country.phone_code + ")",
                };
                this.countryCodeOptions.options.push(options);
              });
              this.countryCodeOptions.value = "+91";
            }
            // console.log(this.countryCodeOptions);
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    requestVerificationOTP() {
      this.isOTPSent = false;
      this.register();
    },
    async register() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        // console.log(this.v$.$errors);
        return;
      }
      let params = {
        // request type is PHONE as the otp sends to phone at the moment
        request_type: "PHONE",
        platform: "WEB",
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        country_code: this.countryCodeOptions.value,
        phone: this.phone,
        standard_id: this.standardOptions.value,
        school_id: this.schoolOptions.value,
        school_name: this.schoolName,
        password: this.password,
        password_confirmation: this.passwordConfirmation,
        acceptTerms: this.acceptTerms,
      };
      console.log(params);
      this.isRegistering = true;
      this.countryCodeOptions.disabled = this.isRegistering;
      this.standardOptions.disabled = this.isRegistering;
      this.schoolOptions.disabled = this.isRegistering;
      await AuthService.register(params)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            this.isEmailValid = true;
            this.isVerificationPending = true;
            setTimeout(() => {
              if (document.querySelector("#otp_field")) {
                this.$refs.focusOTPField.focus();
              }
              this.isOTPSent = true;
              this.$toast.success(response.data.message);
            }, 5000);
          }
          if (response.data.status === "ERROR") {
            this.isRegistering = false;
            this.countryCodeOptions.disabled = this.isRegistering;
            this.standardOptions.disabled = this.isRegistering;
            this.schoolOptions.disabled = this.isRegistering;
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            this.isRegistering = false;
            this.countryCodeOptions.disabled = this.isRegistering;
            this.standardOptions.disabled = this.isRegistering;
            this.schoolOptions.disabled = this.isRegistering;
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async verifyRegistration() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        // console.log(this.v$.$errors);
        return;
      }
      let params = {
        country_code: this.countryCodeOptions.value,
        phone: this.phone,
        otp: this.otp,
      };
      await AuthService.verifyRegisterOTP(params)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            this.$toast.success(response.data.message);
            const userData = {
              token: response.data.token,
              user: response.data.user,
            };
            this.$store.dispatch("auth/authSuccess", userData);
            this.$store.dispatch("auth/authRequest", false);
            if (this.$store.state.auth.nextUrl == "") {
              this.$router.push({
                name: "Profile",
              });
            } else {
              this.$router.push(this.$store.state.auth.nextUrl);
              this.$store.dispatch("auth/updateNextUrl", "");
            }
          }
          if (response.data.status === "ERROR") {
            this.isVerifying = false;
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            this.isVerifying = false;
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
