<template>
  <div class="login coantainer-fluid">
    <div class="container login-wrapper">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h3>
            <span>REGISTER</span> <br />
            An ACCOUNT
          </h3>
          <RegisterPasswordForm v-if="!isRegisterViaOTP" />
          <!-- Note:- RegisterOTPForm is outdated -->
          <!-- <RegisterOTPForm v-if="isRegisterViaOTP" /> -->
          <div class="mb-3 action-div">
            <!-- <span>
              Or, try
              <a
                v-if="!isRegisterViaOTP"
                href="javascript:void(0);"
                @click="toggleRegisterForm"
              >
                Register with Phone
              </a>
              <a
                v-if="isRegisterViaOTP"
                href="javascript:void(0);"
                @click="toggleRegisterForm"
              >
                Register with Email
              </a>
            </span>
            | -->
            <span
              >Have an account!
              <router-link :to="{ name: 'Login' }">Login</router-link></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RegisterPasswordForm from "@/components/RegisterPasswordForm.vue";
// import RegisterOTPForm from "@/components/RegisterOTPForm.vue";

export default {
  name: "Register",
  components: {
    RegisterPasswordForm,
    // RegisterOTPForm,
  },
  data() {
    return {
      isRegisterViaOTP: false,
    };
  },
  methods: {
    toggleRegisterForm() {
      this.isRegisterViaOTP = !this.isRegisterViaOTP;
    },
  },
};
</script>

<style lang="scss">
@import "@/style/login.scss";
</style>
